import React from "react";
import { Outlet } from "react-router-dom";
import Icon from './media/icon.png'
import HeaderIcon from './media/cubic_w.png'

const Application = () => {

    return (
        <div className="App">
            {/* <img className="footer-icon" src={Icon} alt="Cubic"/> */}
            <img className="header-icon" src={HeaderIcon} alt="Cubic"/>
            <div className="header"><h4>Cubic Interactive Overview</h4></div>
            <Outlet />
        </div>
    )
}


export default Application;