import React, {useState} from "react";
import BackBtn from "../Components/BackBtn";
import Map from "../Components/Map";
import Popup from "../Components/Popup";
import LayerMenu from "../Components/LayerMenu";
import { MapData } from "../data/MapDataTwo";

const Page = (options)=>{

    const mapData = MapData().getById(options.id);
    const [data, setData] = useState(mapData);

    const menuItemClick = (evt)=>{
        let idx;
        if(evt.target.getAttribute("class") === "icon-tick"){
            idx = evt.target.parentElement.getAttribute("value");
        } else {
            idx = evt.target.getAttribute("value");
        }
        const visible = data.layers[idx].visible ? data.layers[idx].visible = false : data.layers[idx].visible = true;
        const newValue = {...data};
        newValue.layers[idx].visible = visible;
        setData(_=>{
            return newValue;
        })
    }

    return (
        <div className="page iltsPage">
            <h2 className="page-title">{data.title}</h2>
            <BackBtn url="/"/>
            <Map {...data}/>
            <LayerMenu {...data} menuItemClick={menuItemClick}/>
            <Popup/>
        </div>
    )
}

export default Page;