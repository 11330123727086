import './App.css';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Application from './Application';
import Home from './Pages/HomeTwo';
import Page from './Pages/PageTwo';
import {MapData} from './data/MapDataTwo';

const mapData = MapData();

function App() {
  return (
    <Routes>
      <Route path="/" element={<Application />}>
        <Route index element={<Home />} />
        {Object.keys(mapData.maps).map((id, i)=>{
          return <Route path={id} key={i} element={<Page key={i} id={id} />} />
        })}
      </Route>
    </Routes>

  );
}

export default App;
