import React, {useContext} from "react";
import {usePopup} from '../context/popup.context';
import './InfoBtn.css'


let InfoBtn = (options)=>{

        const {setPopupData} = usePopup();
        

        const {top, left, title, data} = options;
        const btnStyle = {top, left};

        let handleClick = ()=>{
            setPopupData({data, title, visible: true});
        }

    return  <div className="info-btn" style={btnStyle} onClick={handleClick}>+</div>
}


export default InfoBtn;