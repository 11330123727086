

import ILTSBaseMap from '../media/maplayers/ilts_base_map.webp';
import CTCBaseMap from '../media/maplayers/ctc_core_base.webp';
import CTCPopup from '../media/popups/ctc_popup.webp';
import ARDSPopup from '../media/popups/ards_popup.webp'
import ARDSFuture from '../media/maplayers/ards_future_dev.webp'
import ARDSShort from '../media/maplayers/ards_short_term.webp'
import ARDSBaseMap from '../media/maplayers/ards_base.webp'
import AirBaseMap from '../media/maplayers/air_ranges_base.webp'
import MDLVCMap from '../media/maplayers/mdlvc_base.webp'
import MDLVCPreExImg from '../media/maplayers/mdlvc_pre-ex.webp'
import MDLVCViewsImg from '../media/maplayers/mdlvc_views.webp'
import CBRNDScenario from '../media/maplayers/cbrnd_scenario.webp'
import GTRAEx1 from '../media/maplayers/gtra_ex1.webp'
import GTRAEx2 from '../media/maplayers/gtra_ex2.webp'
import GTRABackhaul from '../media/popups/gtra_backhaul.webp'
import GTRACamera from '../media/popups/gtra_camera.webp'
import GTRIteWifi from '../media/popups/gtra_ltewifi.webp'
import GTRAStepUp from '../media/popups/gtra_stepup.webp'
// import CTCBaseMap from '../media/maplayers/ilts_base_map.png';
import CAALRNetImg from '../media/maplayers/ilts_caalr_net.png'
import CAALRPopup from '../media/popups/caalr_popup.webp'
import ILTSUOLFPopup from '../media/popups/uolf_popup.webp'
import ILTSMCSPopup from '../media/popups/ilts_mcs.webp'
import ILTSMTRPopup from '../media/popups/ilts_mtr_popup.webp'
import ASMTIBaseNet from '../media/maplayers/asmti_base_net.webp'
import ASMTIRange from '../media/maplayers/asmti_range.webp'
import ASMTIVisiting from '../media/maplayers/asmti_visiting.webp'
import ASMTIAustrlian from '../media/maplayers/asmti_australian.webp'
import ASMTIPopup from '../media/popups/asmti_popup.webp'
import ASMTISWBTAPopup from '../media/popups/asmti_swbta_popup.webp'
import ASMTISWBTATowerPopup from '../media/popups/swbta_towers_popup.webp'
import ASMTISWBTATowerVisPopup from '../media/popups/asmti_comms_tower_vis_popup.webp'
import CBRNDDetectPopup from '../media/popups/cbrnd_detector.webp'
import CBRNDGasPopup from '../media/popups/cbrnd_popup_3d.webp'
import CBRNDCatsPopup from '../media/popups/cats_cbrnd.webp'
import CBRNDEquipPopup from '../media/popups/cbrnd_equip_popup2.webp'
import ILTSEccCamp from '../media/maplayers/camp_ecc.png'
import ILTSMCS from '../media/maplayers/ilts_mcs.png'
import ILTSMtr from '../media/maplayers/ilts_mtr.png'
import ILTSMtrNet from '../media/maplayers/ilts_mtr_net.png'
import ILTSUOLF from '../media/maplayers/ilts_uolf.png'
import ILTSUOLFNet from '../media/maplayers/ilts_uolf_net.png'
import ILTSCaalr from '../media/maplayers/ilts_caalr.png'
import CTCExcon from '../media/maplayers/ctc_core_excon.webp'
import CTCAAR from '../media/maplayers/ctc_core_aar.webp'
import CTCCoaching from '../media/maplayers/ctc_core_coaching.webp'

import NQSparkBaseMap from '../media/maplayers/nqspark_base_map.webp'
import BlueRoomBaseMap from '../media/maplayers/blueroom_base_map.jpg'
import CATSBaseMap from '../media/maplayers/cats_base_map.webp'
import CATSSwitch from '../media/maplayers/cats_switch.webp'
import CATSSecret from '../media/maplayers/cats_secret.webp'
import JOTBaseMap from '../media/maplayers/jot_base_map.webp'
import JOTMCT from '../media/maplayers/jot_mct.webp'
import JOTETC from '../media/maplayers/jot_etc.webp'
import VETChoiceBaseMap from '../media/maplayers/vet_choice_base_map.webp'
import EPANBaseMap from '../media/maplayers/epan_base_map.webp'
import EPANPup from '../media/maplayers/epan_pup.webp'
import ADVTrain2BaseMap from '../media/maplayers/adv_train2_base_map.webp'
import ADVTrain2Trailer from '../media/popups/adv_train2_trailer.webp'
import ADVTrain2Drone from '../media/maplayers/adv_train2_drone.webp'
import ADVTrain2DroneFlash from '../media/maplayers/adv_train2_drone_flash.webp'
import ADVTrain2DronePopup from '../media/popups/adv_train2_drone.webp'
import ADVTrain2MainPopup from '../media/popups/adv_train2_main.webp'
import ADVTrain2CATSPopup from '../media/popups/Serial 1 DEVEX.mp4'
import ADVTrain2PayloadDrop from '../media/popups/Serial 3 DEVEX.mp4'
import ADVTrainBaseMap from '../media/maplayers/adv_train_base_map.webp'
import ADVTrainDrone from '../media/maplayers/adv_train_drone.webp'
import SAFTIBaseMap from '../media/maplayers/safti_base_map.webp'
import SAFTIBldLabels from '../media/maplayers/safti_bld_labels.webp'
import SAFTIUndergroundBld from '../media/maplayers/safti_under_bld.webp'
import SAFTIEquip from '../media/maplayers/safti_equip.webp'
import SAFTIUnderBldPopup from '../media/popups/safti_under_popup.webp'
import SAFTITrapdoorPopup from '../media/popups/safti_trapdoor_popup.webp'
import ShootbackBaseMap from '../media/maplayers/shootback_base_map.webp'
import ShootbackIR from '../media/maplayers/shootback_ir.webp'
import ShootbackID from '../media/maplayers/shootback_identify.webp'
import ShootbackFeatures from '../media/popups/shootback_features.webp'
import ShootbackInfo from '../media/popups/shootback_info.webp'
import MDTBaseMap from '../media/maplayers/mdt_base_map.webp'
import MDTInfo from '../media/maplayers/mdt_info.webp'

import ManWearBase from '../media/maplayers/manwear_base.webp'
import ManWearFLashLayer from '../media/maplayers/manwear_flash_layer2.webp'

import VehicleKitsBase from '../media/maplayers/vehiclekit_base.webp'
import VehicleKitsVHI from '../media/maplayers/vehiclekit_vhi.webp'
import VehicleKitsRLDMS from '../media/maplayers/vehiclekit_rldms.webp'
import VehicleKitsSSGD from '../media/maplayers/vehiclekit_ssgd.webp'

import NavySynthTrain from '../media/maplayers/navy_synth_train.webp'
import NavySynthTrainNSWC from '../media/maplayers/navy_synth_train_NSWC.webp'
import NavySynthTrainNCTE from '../media/maplayers/navy_synth_train_NCTE.webp'

export const MapData = () => {

    const maps = {
        ilts: {
            title: "Integrated Land Target System",
            baseMapImgs: [ILTSBaseMap, ILTSMCS],
            baseInfoBtns: [{
                title: "MCS",
                top: "80%",
                left: "25%",
                data: ILTSMCSPopup
            }],
            layers: [
                {
                    name: "MTR",
                    visible: true,
                    imgs: [
                        ILTSMtr
                    ],
                    infoBtns: [{
                        title: "MTR Range Info",
                        top: "80%",
                        left: "60%",
                        data: ILTSMTRPopup
                    }]
                },
                {
                    name: "MTR Network",
                    visible: false,
                    imgs: [
                        ILTSMtrNet
                    ],
                    infoBtns: []
                },
                {
                    name: "CAALR",
                    visible: false,
                    imgs: [ILTSCaalr],
                    infoBtns: [{
                        title: "CAALR",
                        top: "35%",
                        left: "40%",
                        data: CAALRPopup
                    }]
                },
                {
                    name: "CAALR Network",
                    visible: false,
                    imgs: [CAALRNetImg],
                    infoBtns: []
                },
                {
                    name: "UOLF",
                    visible: false,
                    imgs: [ILTSUOLF],
                    infoBtns: [{
                        title: "UOLF",
                        top: "59%",
                        left: "67%",
                        data: ILTSUOLFPopup
                    }]
                },
                {
                    name: "UOLF Network",
                    visible: false,
                    imgs: [ILTSUOLFNet],
                    infoBtns: []
                }
            ],

        },
        ctc: {
            title: "Army Training",
            baseMapImgs: [CTCBaseMap],
            baseInfoBtns: [{
                title: "Training Services",
                top: "37%",
                left: "60%",
                data: CTCPopup
            }],
            layers: [
                {
                    name: "Excon",
                    visible: false,
                    imgs: [
                        CTCExcon
                    ],
                    infoBtns: []
                },
                {
                    name: "AAR",
                    visible: false,
                    imgs: [CTCAAR],
                    infoBtns: []
                },
                {
                    name: "LPA",
                    visible: false,
                    imgs: [CTCCoaching],
                    infoBtns: []
                }
            ],
        },
        cats: {
            title: "CATS Metrix",
            baseMapImgs: [CATSBaseMap],
            baseInfoBtns: [],
            layers: [
                {
                    name: "CATS in EXCON on SECRET",
                    visible: false,
                    imgs: [
                        CATSSecret
                    ],
                    infoBtns: []
                },
                {
                    name: "CATS Switchblade Sim",
                    visible: false,
                    imgs: [CATSSwitch],
                    infoBtns: []
                }
            ],
        },
        epan: {
            title: "Enhanced Personal Area Network (EPAN)",
            baseMapImgs: [EPANBaseMap],
            baseInfoBtns: [],
            layers: [
                {
                    name: "PUP Integration",
                    visible: false,
                    imgs: [EPANPup],
                    infoBtns: []
                }
            ],
        },
        adv_train: {
            title: "Exercise and Advanced Training Services",
            baseMapImgs: [ADVTrainBaseMap],
            baseInfoBtns: [],
            layers: [
                {
                    name: "Simulating Weaponised Drones",
                    visible: false,
                    imgs: [
                        ADVTrainDrone
                    ],
                    infoBtns: []
                }
            ],
        },
        adv_training: {
            title: "Exercise and Advanced Training Services",
            baseMapImgs: [ADVTrain2BaseMap],
            baseInfoBtns: [{
                title: "Advanced Training Services",
                top: "40%",
                left: "60%",
                data: ADVTrain2MainPopup
            }],
            layers: [
                {
                    name: "Drone Capabilities",
                    visible: false,
                    imgs: [
                        ADVTrain2Drone,
                        {
                            data: ADVTrain2DroneFlash,
                            class: "pulsate"
                        }
                    ],
                    infoBtns: [{
                        title: "Drone Capabilities",
                        top: "24%",
                        left: "34%",
                        data: ADVTrain2DronePopup
                    },
                    {
                        title: "UAS Deployable Station",
                        top: "35%",
                        left: "29%",
                        data: ADVTrain2Trailer
                    },
                    {
                        title: "Explosive Payload Drop",
                        top: "40%",
                        left: "42%",
                        data: ADVTrain2PayloadDrop
                    },
                    {
                        title: "Drone Simulation Integration",
                        top: "72%",
                        left: "51%",
                        data: ADVTrain2CATSPopup
                    }]
                }
            ],
        },
        vet_choice: {
            title: "Veteran Employer of Choice",
            baseMapImgs: [VETChoiceBaseMap],
            baseInfoBtns: [],
            layers: [
            ],
        },
        jot: {
            title: "Javelin Outdoor Trainer (JOT)",
            baseMapImgs: [JOTBaseMap],
            baseInfoBtns: [],
            layers: [
                {
                    name: "Enhanced Training Capability",
                    visible: false,
                    imgs: [
                        JOTETC
                    ],
                    infoBtns: []
                },
                {
                    name: "Multi-Code Transmitter",
                    visible: false,
                    imgs: [
                        JOTMCT
                    ],
                    infoBtns: []
                }
            ],
        },
        blueroom: {
            title: "",
            baseMapImgs: [BlueRoomBaseMap],
            baseInfoBtns: [],
            layers: [],
        },
        nqspark: {
            title: "",
            baseMapImgs: [NQSparkBaseMap],
            baseInfoBtns: [],
            layers: [],
        },
        shootback: {
            title: "Shootback Target",
            baseMapImgs: [ShootbackBaseMap],
            baseInfoBtns: [{
                title: "Shootback Features",
                top: "50%",
                left: "50%",
                data: ShootbackInfo
            }],
            layers: [
                {
                    name: "Infrared",
                    visible: false,
                    imgs: [ShootbackIR],
                    infoBtns: []
                },
                {
                    name: "Optical Target Identification",
                    visible: false,
                    imgs: [ShootbackID],
                    infoBtns: [{
                        title: "Example Capabilities",
                        top: "48%",
                        left: "58%",
                        data: ShootbackFeatures
                    }]
                }
            ],
        },
        multidomain_training: {
            title: "Multi-domain Training",
            baseMapImgs: [MDTBaseMap],
            baseInfoBtns: [],
            layers: [
                {
                    name: "UI and Integration",
                    visible: false,
                    imgs: [MDTInfo],
                    infoBtns: []
                }
            ],
        },
        navy: {
            title: "Navy Synthetic Training and Planning",
            baseMapImgs: [NavySynthTrain],
            baseInfoBtns: [],
            layers: [{
                name: "Future NSWC",
                visible: false,
                imgs: [
                    NavySynthTrainNSWC
                ],
                infoBtns: []
            },
            {
                name: "NCTE",
                visible: false,
                imgs: [
                    NavySynthTrainNCTE
                ],
                infoBtns: []
            }],
        },
        asmti: {
            title: "Australia - Singapore Military Training Initiative (ASMTI)",
            baseMapImgs: [ILTSBaseMap],
            baseInfoBtns: [{
                title: "Logical Network",
                top: "71%",
                left: "25%",
                data: ASMTIPopup
            },
            {
                title: "LVC Communication Tower",
                top: "58%",
                left: "31%",
                data: ASMTISWBTATowerPopup
            },
            {
                title: "LVC Communication Tower - 2",
                top: "44%",
                left: "87%",
                data: ASMTISWBTATowerVisPopup
            },
            {
                title: "SWBTA Infrastructure",
                top: "56%",
                left: "63%",
                data: ASMTISWBTAPopup
            }],
            layers: [
                {
                    name: "Range Management",
                    visible: false,
                    imgs: [
                        ASMTIRange
                    ],
                    infoBtns: []
                },
                {
                    name: "Visiting Forces",
                    visible: false,
                    imgs: [ASMTIVisiting],
                    infoBtns: []
                },
                {
                    name: "Australian Forces",
                    visible: false,
                    imgs: [ASMTIAustrlian],
                    infoBtns: []
                }
            ],
        },
        manWear: {
            title: "Soldier Kits",
            baseMapImgs: [ManWearBase],
            baseInfoBtns: [],
            layers: [
                {
                    name: "Pan Harness",
                    visible: false,

                    imgs: [
                        ManWearFLashLayer
                    ],
                    class: "pulsate",
                    infoBtns: []
                }
            ],
        },
        vehiclekits: {
            title: "Vehicle Kits",
            baseMapImgs: [VehicleKitsBase],
            baseInfoBtns: [],
            layers: [
                {
                    name: "VHI",
                    visible: false,
                    imgs: [
                        VehicleKitsVHI
                    ],
                    class: "pulsate",
                    infoBtns: []
                },
                {
                    name: "SSGD",
                    visible: false,
                    imgs: [
                        VehicleKitsSSGD
                    ],
                    class: "pulsate",
                    infoBtns: []
                },
                {
                    name: "RLDMS",
                    visible: false,
                    imgs: [
                        VehicleKitsRLDMS
                    ],
                    class: "pulsate",
                    infoBtns: []
                }
            ],
        },
        air_ranges: {
            title: "Air Ranges and Debriefing Systems",
            baseMapImgs: [ARDSBaseMap],
            baseInfoBtns: [],
            layers: [
                {
                    name: "Short Term",
                    visible: false,
                    imgs: [ARDSShort],
                    infoBtns: []
                },
                {
                    name: "Future Dev",
                    visible: false,
                    imgs: [ARDSFuture],
                    infoBtns: []
                }
            ],

        },
        mdlvc: {
            title: "Multi Domain LVC",
            baseMapImgs: [MDLVCMap],
            baseInfoBtns: [],
            layers: [
                {
                    name: "Pre Exercise",
                    visible: false,
                    imgs: [MDLVCPreExImg],
                    infoBtns: []
                },
                {
                    name: "LVC Views",
                    visible: false,
                    imgs: [MDLVCViewsImg],
                    infoBtns: []
                }
            ],

        },
        gtra: {
            title: "Open Architecture",
            baseMapImgs: [ILTSBaseMap],
            baseInfoBtns: [],
            layers: [
                {
                    name: "Exercise 1",
                    visible: true,
                    imgs: [GTRAEx1],
                    infoBtns: [{
                        title: "Camera Node",
                        top: "30%",
                        left: "25%",
                        data: GTRACamera
                    }]
                },
                {
                    name: "Exercise 2",
                    visible: false,
                    imgs: [GTRAEx2],
                    infoBtns: [{
                        title: "Backhaul Node",
                        top: "68%",
                        left: "46%",
                        data: GTRABackhaul
                    },
                    {
                        title: "LTE / WIFI",
                        top: "38%",
                        left: "68%",
                        data: GTRIteWifi
                    },
                    {
                        title: "GRS",
                        top: "68%",
                        left: "67%",
                        data: GTRAStepUp
                    }]
                }
            ],

        },
        cbrnd: {
            title: "Chemical, Biological, Radiological and Nuclear (CBRN)",
            baseMapImgs: [ILTSBaseMap],
            baseInfoBtns: [],
            layers: [
                {
                    name: "Scenario",
                    visible: false,
                    imgs: [CBRNDScenario],
                    infoBtns: [{
                        title: "CBRN Simulated Chemical Hazard",
                        top: "45%",
                        left: "55%",
                        data: CBRNDGasPopup
                    }, {
                        title: "CBRN Simulated Devices",
                        top: "51%",
                        left: "48%",
                        data: CBRNDEquipPopup
                    }]
                },
                {
                    name: "EXCON",
                    visible: false,
                    imgs: [ILTSEccCamp],
                    infoBtns: [{
                        title: "CATS 2D Interface - Chemical Simulation ",
                        top: "77%",
                        left: "51%",
                        data: CBRNDCatsPopup
                    }]
                }

            ],

        },
        safti: {
            title: "SAFTI City",
            baseMapImgs: [SAFTIBaseMap],
            baseInfoBtns: [],
            layers: [
                {
                    name: "Buildings",
                    visible: false,
                    imgs: [SAFTIBldLabels],
                    infoBtns: []
                },
                {
                    name: "Underground Building",
                    visible: false,
                    imgs: [SAFTIUndergroundBld],
                    infoBtns: [{
                        title: "Simulated Underground Building",
                        top: "40%",
                        left: "56%",
                        data: SAFTIUnderBldPopup
                    }]
                },
                {
                    name: "Equipment",
                    visible: false,
                    imgs: [SAFTIEquip],
                    infoBtns: [{
                        title: "Trapdoor",
                        top: "40%",
                        left: "82%",
                        data: SAFTITrapdoorPopup
                    }]
                }
            ],
        }
    }

    const getById = (id) => {
        if (!maps[id]) throw new Error(`No map with that ID exists: ${id}`);
        return maps[id];
    }
    return {
        maps,
        getById
    };

}
