import React, { createContext, useState, useContext } from 'react'
import CUBICIcon from '../media/icon.png'

export const PopupContext = createContext();

export const usePopup = ()=> useContext(PopupContext);

export const PopupProvider = ({ children }) => {

    const initPopup = {
            title: "Cubic",
            data: CUBICIcon,
            visible: false
    }

    const [popupData, setPopupData] = useState(initPopup);

    const value = { popupData, setPopupData };
    return <PopupContext.Provider value={value}>{children}</PopupContext.Provider>
}