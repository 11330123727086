import React from "react";
import { Link } from "react-router-dom";
import './Tile.css'


const Tile = (options) => {

    const { linkUrl, bgImg, title } = options;
    let width = options.width ? options.width+'%' : "";
    let height = options.width ? options.width+1+"%" : "";

    let className = "tile able-tile";
    let opacity = "default";
    //check if tile doesnt link anywhere
    if(linkUrl===undefined || linkUrl===''){
        opacity = "0.3";
        className = "tile disabled-tile";
    }
    if(options.blank === true){
        opacity = "0";
    }
    const divStyle = {width, height, opacity}

    return (
            <Link to={linkUrl}>
                <div className={className} style={divStyle}>
                    <img src={bgImg} alt="" />
                    <span>{title}</span>
                </div>
            </Link> 
    )
}

export default Tile;