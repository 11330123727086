import React from "react";
import { Link } from "react-router-dom";
import BackArrowImg from '../media/ba_white.png';

const BackBtn = (options)=>{

    return (
        <Link to={options.url}><div className="back-btn">
            <img src={BackArrowImg} alt="" />
            <h4>Home</h4>
        </div></Link>
    )
}

export default BackBtn;