import React from "react";
import InfoBtn from "./InfoBtn";
import './Map.css';

const Map = (options) => {

    const getActiveImgs = () => {
        return options.layers.map((layer, idx) => {
            if (!layer.visible) return '';
            return layer.imgs.map((img, i) => {
                if(typeof img === "object"){
                    console.log(img)
                    return <img class={img.class} key={idx + ":" + i} src={img.data} alt={layer.name} />
                }
                return <img class={layer.class} key={idx + ":" + i} src={img} alt={layer.name} />
            })
        })
    };

    const getActiveInfoIcons = () => {
        return options.layers.map((layer, idx) => {
            if (!layer.visible) return '';
            return layer.infoBtns.map((infoBtn, i) => {
                return <InfoBtn {...infoBtn} />
            })
        })

    };


    return (
        <div className="map">

            {options.baseMapImgs.map((img,i) => {
                return <img key={i} src={img} alt="" />
            })}

            {options.baseInfoBtns.map((btn, i) => {
                return <InfoBtn key={i}{...btn} />
            })}
            {/* this will create all the image layers on the map */ }
            { getActiveImgs() }
            { getActiveInfoIcons() }
        </div>
    )
}

export default Map;