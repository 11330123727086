import React from "react";
import './Popup.css'
import { usePopup} from '../context/popup.context'

let Popup = (options) => {

    const { popupData, setPopupData } = usePopup()

    //this will hide the popup menu
    let handleClick = _ => {
        Array.from(document.getElementsByTagName("video")).forEach(video=> {video.pause(); video.currentTime = 0;});
        setPopupData(prevValue=>{
            return {...prevValue, visible: false}
        })
    }

    const pStyle = {
        display: popupData.visible===true ? "block": "none",
        // width: popupData.visible===true ? "max-content" : "0px"
    }



    return (
        <div className="popup-menu" id="popup" style={pStyle}>
            <div onClick={handleClick} className="close-btn">{'\u2715'}</div>
            <div className="popup-header"><span>{popupData.title.toUpperCase()}</span></div>
            {/* {typeof popupData.data === "string" 
                    ? <img className="popup-img-overview" alt="curPopup.title.toUpperCase()" src={popupData.data} /> 
                    : <div>{popupData.data}</div>
            } */}
            {popupData.data.includes("webp") ||  popupData.data.includes("jpg") ||  popupData.data.includes("png")
                    ? <img className="popup-img-overview" alt="curPopup.title.toUpperCase()" src={popupData.data} /> 
                    : popupData.data.includes("mp4") ||  popupData.data.includes("wmv") ||  popupData.data.includes("mkv")
                    
                    ? <video className="popup-img-overview" alt="curPopup.title.toUpperCase()" controls autoPlay src={popupData.data} ></video> 
                    : <div>{popupData.data}</div>
            }
        </div>
    )
}

export default Popup;